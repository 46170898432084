import { Controller } from "@hotwired/stimulus"

export default class AccordionController extends Controller {
  static targets = ["details", "detailImages"]

  changeOpenDetails(event) {
    this.detailsTargets.map(details => {
      details.dataset.hiding = event.target !== details
      details.open = event.target === details
    })

    this.detailImagesTargets.map(image => {
      image.classList.add('diagram-accordion__image--hidden')

      if (image.dataset.id === event.target.dataset.id) {
        image.classList.remove('diagram-accordion__image--hidden')
      }
    })
  }

  blockHideDetails(event) {
    if(event.target.dataset.hiding === "false") {
      event.preventDefault()
    }
  }
}
