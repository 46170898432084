import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  static values = { activeClass: String }
  static targets = [ "tab", "tabPanel" ]


  initialize() {
    this.showTab()
  }

  change(e){
    this.index = this.tabTargets.indexOf(e.target)
    this.showTab(this.index)
  }

  showTab() {
    this.tabPanelTargets.forEach((el, i) => {
      if(i === this.index){
        el.classList.remove('display-none')
      } else {
        el.classList.add('display-none')
      }
    })

    this.tabTargets.forEach((el, i) => {
      if(i === this.index){
        el.classList.add(this.activeClass)
      } else {
        el.classList.remove(this.activeClass)
      }
    })
  }

  get index() {
    return parseInt(this.data.get('index'))
  }

  get activeClass() {
    return this.activeClassValue || 'tab--active'
  }

  set index(value) {
    this.data.set("index", value)
    this.showTab()
  }
}
