import * as Honeybadger from '@honeybadger-io/js'
if (process.env.RAILS_ENV === 'production' &&
  !process.env.HONEYBADGER_SOURCE_MAP_DISABLED) {
  Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    environment: process.env.HONEYBADGER_ENV, // ‘production’ or ‘review-app’ from app.json
    revision: process.env.SOURCE_VERSION, // provided by heroku
  })
}

import '@slightlyoff/lite-vimeo';
import { Application } from '@hotwired/stimulus'
import AccordionController from "./controllers/accordion_controller.js"
import TabsController from "./controllers/tabs_controller.js"

import { getCLS, getFID, getLCP } from 'web-vitals';

// Shoelace components
// If don't reference the Shoelace class webpack does not include in the build
import SlDetails from '@shoelace-style/shoelace/dist/components/details/details.component.js'
SlDetails.define('sl-details')

function sendToGoogleAnalytics({ name, delta, value, id }) {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  gtag('event', name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta, // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  });
}

getCLS(sendToGoogleAnalytics);
getFID(sendToGoogleAnalytics);
getLCP(sendToGoogleAnalytics);

// Stimulus configuration
const application = Application.start()
application.register("accordion", AccordionController)
application.register("tabs", TabsController)
import "@hotwired/turbo-rails"
